import { collection, doc, getDocs, getFirestore, query, updateDoc, where } from "firebase/firestore"
import Kreis from "../Kreise/kreis"
import Adresse from "./adresse"
import Bank from "./bank"
import Funktion from "./funktion"
import userConverter from "./userConverter"
import store from "@/store"
import md5 from "md5"
import Ort from "./ort"

class User {
    constructor(
        id,
        rolle,
        vorname,
        nachname,
        email,
        funktionen = [],
        adresse,
        bank,
        bank_last_changed,
        photoUrl,
        customFields,
        kreditorennummer = '',
        rollen = [],
        signatur,
        benachrichtigungen,
        meine_sitzungen = [],
        kfz_kennzeichen,
        haeufige_orte = [],
        darkmode = false,
        fill = false,
        kreditorennummer_verband = '',
        favoriten_orte = []
    ) {
        this.id = id
        //this.rolle = rolle || 'Mitarbeiter'
        this.vorname = vorname
        this.nachname = nachname
        this.displayName = vorname + ' ' + nachname
        this.email = email
        this.funktionen = funktionen.map(funktion => {
            return new Funktion(funktion.ebene, funktion.gebiet, funktion.abteilung, funktion.funktion, funktion.freitext, funktion.readonly)
        })
        this.adresse = adresse instanceof Adresse ? adresse : new Adresse(adresse?.strasse, adresse?.nr, adresse?.plz, adresse?.ort, adresse?.additonalName)
        this.bank = bank instanceof Bank ? bank : new Bank(customFields?.phrase, bank)
        this.bank_last_changed = bank_last_changed || 'Unverändert'
        this.photoUrl = photoUrl
        this.customFields = Object.assign({}, customFields)
        this.rollen = rollen || []
        if(!this.rollen.find(item => item == rolle)){
            this.rollen.push(rolle)
        }
        this.signatur = signatur
        this.benachrichtigungen = Object.assign({ user_notification: false, kv_notification: false, kk_notification: false, abteilung_notification: false, finanzen_notification: false, ka_notification: false }, benachrichtigungen)
        if(customFields.kreis){
            this.kreis = customFields.kreis
        }
        this.meine_sitzungen = meine_sitzungen
        this.kfz_kennzeichen = kfz_kennzeichen || ''
        this.haeufige_orte = haeufige_orte.map(ort => {
            return new Ort(ort.id, ort.additionalName, ort.strasse, ort.nr, ort.plz, ort.ort, ort.beschreibung, ort.haeufigkeit)
        })
        this.favoriten_orte = favoriten_orte.map(ort => {
            return new Ort(ort.id, ort.additionalName, ort.strasse, ort.nr, ort.plz, ort.ort, ort.beschreibung, ort.haeufigkeit)
        })
        this.haeufige_orte.sort((a,b) => b.haeufigkeit - a.haeufigkeit)
        this.darkmode = darkmode
        this.fill = fill
        this.kreditorennummer = kreditorennummer
        this.kreditorennummer_verband = kreditorennummer_verband
        this.ausschuesse = []
        this.csv_export = customFields?.csv_export || false
        this.checkRollen()
    }

    async checkRollen(){
        if(!this.email) return

        const docs = await getDocs(query(collection(getFirestore(), 'Kreisausschuss'), where('email', '==', this.email)))
        
        if(docs.empty) return

        this.ausschuesse = []
        docs.forEach(async item => {
            this.ausschuesse.push(Object.assign({}, item.data()))
        })
        
        return true
    }

    async updateHaeufigeOrte(){
        const daten = this.toFirestore()
        const ref = doc(getFirestore(), 'User/'+this.id)
        return updateDoc(ref, {
            haeufige_orte: this.haeufige_orte.map(ort => {
                return ort.toFirestore()
            })
        }).then(() => {
          return true  
        })
    }
    
    async updateFavoritenOrte(){
        const daten = this.toFirestore()
        const ref = doc(getFirestore(), 'User/'+this.id)
        return updateDoc(ref, {
            favoriten_orte: this.favoriten_orte.map(ort => {
                return ort.toFirestore()
            })
        }).then(() => {
          return true  
        })
    }
    
    async saveUser(){
        const daten = this.toFirestore()
        const ref = doc(getFirestore(), 'User/'+this.id)
        return updateDoc(ref, daten).then(() => {
          return true  
        })
    }

    kreismember(kreis){
        const member = (['Kreisvorsitzender', 'Kreiskassierer'].includes(this.rolle) || this.rollen?.includes('Kreisvorsitzender') || this.rollen?.includes('Kreiskassierer')) ? true : false
        if(member && kreis){
            return kreis == this.customFields.kreis
        }
        return member
    }

    kv(kreis){
        const member = (this.rolle == 'Kreisvorsitzender' || this.rollen?.includes('Kreisvorsitzender')) ? true : false
        if(member && kreis){
            return kreis == this.customFields.kreis
        }
        return member
    }

    kk(kreis){
        const member = (this.rolle == 'Kreiskassierer' || this.rollen?.includes('Kreiskassierer') ) ? true : false
        if(member && kreis){
            return kreis == this.customFields.kreis
        }
        return member
    }

    ka(ausschuss){
        const member = this.ausschuesse.length > 0 ? true : false
        if(member && ausschuss){
            return this.ausschuesse.find(item => item.ausschussId == ausschuss) ? true : false
        }
        return member
    }

    abteilungsleiter(abteilung){
        const member = (this.rolle == 'Abteilungsleiter' || this.rollen?.includes('Abteilungsleiter') ) ? true : false
        if(member && abteilung){
            return abteilung == md5(this.customFields.abteilung) || abteilung == this.customFields.abteilung
        }
        return member
    }

    finanzen(){
        return (this.rolle == 'Finanzen' || this.rollen?.includes('Finanzen') ) ? true : false
    }
    
    isAdmin(){
        return (this.rolle == 'Admin' || this.rollen?.includes('Admin') ) ? true : false
    }

    addFunktion(data){
        if(data instanceof Funktion){
            this.funktionen.push(data)
        }
        else if(data && data.ebene) {
            this.funktionen.push(new Funktion(data.ebene, data.gebiet, data.abteilung, data.funktion, data.freitext, data.readonly))
        }
    }

    sitzung_hinzufuegen(name){
        if(name){
            this.meine_sitzungen.push({
                id: md5(name),
                name: name
            })
            this.saveUser()
        }
    }
    
    sitzung_entfernen(i){
        this.meine_sitzungen = this.meine_sitzungen.filter((item, index) => i != index)
        this.saveUser()
    }

    toFirestore(){
        return {
            id: this.id,
            vorname: this.vorname || '',
            nachname: this.nachname || '',
            funktionen: this.funktionen.map(item => {
                return item.toFirestore()
            }),
            fill: this.customFields?.fill || false,
            adresse: this.adresse.toFirestore(),
            bank: Object.assign({}, this.bank.toFirestore()),
            bank_last_changed: this.bank_last_changed,
            signatur: this.signatur || '',
            rollen: this.rollen || [],
            benachrichtigungen: this.benachrichtigungen,
            meine_sitzungen: this.meine_sitzungen,
            kfz_kennzeichen: this.kfz_kennzeichen,
            abteilung: this.customFields?.abteilung || '',
            haeufige_orte: this.haeufige_orte.map(ort => {
                return ort.toFirestore()
            }),
            favoriten_orte: this.favoriten_orte.map(ort => {
                return ort.toFirestore()
            }),
            darkmode: this.darkmode,
            kreditorennummer: this.kreditorennummer || '',
            kreditorennummer_verband: this.kreditorennummer_verband || '',
            csv_export: this.customFields?.csv_export || false,
            kreis: this.kreis ? this.kreis : ''
        }
    }
}

export default User