import Adresse from "@/classes/User/adresse";
import md5 from "md5";
import Fahrtkosten from "./fahrtkosten";
import Tagegeld from "./tagegeld";
import Uebernachtung from "./uebernachtung";
import TagegeldTag from "./tagegeldTag";

class Reisekosten {
    constructor(
        id,
        datum,
        veranstaltung,
        ort = {
            strasse: '',
            nr: '',
            plz: '',
            ort: '',
            additionalName: '',
        },
        route = {
            start: new Adresse(),
            ziel: new Adresse(),
            wegpunkte: [],
            nur_mitfahrer: false,
            kilometer: {
                value: 0,
                calculated: true,
                begruendung: ''
            }
        },
        tagegeld = new Tagegeld(),
        uebernachtungen = [],
        fahrtkosten = [],
        summe,
        bemerkungen = '',
        kostenstelle = '',
        kostentraeger = '',
        kennzeichen = '',
        mehrtaegig = false,
        bis_datum = ''
    ) {
        this.id = id || md5(Date.now())
        this.datum = datum
        this.veranstaltung = veranstaltung
        this.ort = ort
        this.route = {
            start: new Adresse(route.start.strasse, route.start.nr, route.start.plz, route.start.ort, route.start.additionalName),
            ziel: new Adresse(route.ziel.strasse, route.ziel.nr, route.ziel.plz, route.ziel.ort, route.ziel.additionalName),
            wegpunkte: Array.isArray(route.wegpunkte) ? route.wegpunkte.map(item => {
                return new Adresse(item.strasse, item.nr, item.plz, item.ort, item.additionalName)
            }) : [],
            type: route.type || 'Start/Ziel',
            nur_mitfahrer: route.nur_mitfahrer || false,
            kilometer: route.kilometer
        },
        this.tagegeld = tagegeld instanceof Tagegeld ? tagegeld : new Tagegeld(tagegeld?.abwesend_von, tagegeld?.abwesend_bis, tagegeld?.tagegeld, tagegeld?.calculated)
        this.fahrtkosten = fahrtkosten.map(val => {
            val = new Fahrtkosten(val.pkw, val.bahn, val.flugzeug, val.zuschlaege, val.taxi, val.belege)
        })
        this.uebernachtungen = uebernachtungen.map(val => {
            val = new Uebernachtung(val.kosten_pro_nacht, val.anz_naechte, val.belege, val.summe)
        })
        this.summe = summe || 0
        this.bemerkungen = bemerkungen
        this.kostenstelle = kostenstelle
        this.kostentraeger = kostentraeger
        this.details = false
        this.kennzeichen = kennzeichen
        this.mehrtaegig = mehrtaegig
        this.bis_datum = bis_datum
    }

    calculateTage(){
        var start, ende, dauer
        this.tagegeld.calculated.tage = []
        if(this.mehrtaegig == false){
            this.tagegeld.calculated.active = true
            start = this.tagegeld.get_abwesend_von().split(':')
            ende = this.tagegeld.get_abwesend_bis().split(':')
            dauer = Math.round(parseInt(ende[0]) - parseInt(start[0])) + ((parseInt(ende[1]) - parseInt(start[1])) / 60)
            this.tagegeld.calculated.tage.push(
                new TagegeldTag(this.datum, dauer, 0)
            )
        }
        else {
            this.tagegeld.calculated.active = true
            var startDate = new Date(this.datum)
            var endDate = new Date(this.bis_datum) 
            var date = new Date(this.datum)
            while(date.getTime() <= endDate.getTime()){
                if(date.getTime() == startDate.getTime()){
                    start = this.tagegeld.get_abwesend_von().split(':')
                    ende = '23:59'.split(':')
                    dauer = Math.round(parseInt(ende[0]) - parseInt(start[0])) + ((parseInt(ende[1]) - parseInt(start[1])) / 60)
                    this.tagegeld.calculated.tage.push(
                        new TagegeldTag(this.datum, dauer, 0)
                    )
                }
                else if(date.getTime() == endDate.getTime()){
                    start = '00:00'.split(':')
                    ende = this.tagegeld.get_abwesend_bis().split(':')
                    dauer = Math.round(parseInt(ende[0]) - parseInt(start[0])) + ((parseInt(ende[1]) - parseInt(start[1])) / 60)
                    this.tagegeld.calculated.tage.push(
                        new TagegeldTag(this.bis_datum, dauer, 0)
                    )
                }
                else {
                    this.tagegeld.calculated.tage.push(
                        new TagegeldTag(date.toJSON().split('T')[0], 24, 0)
                    )
                }
                date.setDate(date.getDate() + 1)
            }
        }
        this.tagegeld.calculateSumme()
    }

    generateTagegelder(data){
        var tagegelder = []
        data.forEach(t => {
            tagegelder.push(new Tagegeld(t.abwesend_von, t.abwesend_bis, t.summe))
        })
        return tagegelder
    }
    
    generateFahrtkosten(data){
        var fahrtkosten = []
        fahrtkosten.map()
        data.forEach(f => {
            fahrtkosten.push(new Fahrtkosten(f.pkw, f.bahn, f.flugzeug, f.zuschlaege, f.taxi, f.belege))
        })
        return fahrtkosten
    }

    calculateSumme(){
        var summe = 0
        summe = parseFloat(this.route.kilometer.value) * 0.3
        this.fahrtkosten.forEach(val => {
            summe += val.calculateSumme()
        })
        summe += this.tagegeld.summe
        this.uebernachtungen.forEach(val => {
            summe += val.calculateSumme()
        })
        return summe.toFixed(2)
    }

    get_fahrtkosten(){
        var summe = 0
        summe = parseFloat(this.route.kilometer.value) * 0.3
        return summe.toFixed(2)
    }

    parseDatum(){
        if(this.datum){
            const datum = this.datum.split('-')
            return datum[2]+'.'+datum[1]+'.'+datum[0]
        }
        return ''
    }

    parseBisDatum(){
        if(this.bis_datum){
            const datum = this.bis_datum.split('-')
            return datum[2]+'.'+datum[1]+'.'+datum[0]
        }
        return ''
    }

    toFirestore(){
        return {
            id: this.id,
            datum: this.datum || '',
            veranstaltung: this.veranstaltung,
            ort: this.ort,
            route: {
                start: this.route.start.toFirestore(),
                ziel: this.route.ziel.toFirestore(),
                wegpunkte: this.route.wegpunkte.map(item => {
                    if(item instanceof Adresse) {
                        return item.toFirestore()
                    }
                    return item
                }),
                type: this.route.type,
                nur_mitfahrer: this.route.nur_mitfahrer,
                kilometer: this.route.kilometer,
            },
            tagegeld: this.tagegeld.toFirestore(),
            fahrtkosten: this.fahrtkosten.map(val => {
                val = val.toFirestore()
            }),
            uebernachtungen: this.uebernachtungen.map(val => {
                val = val.toFirestore()
            }),
            summe: this.calculateSumme(),
            bemerkungen: this.bemerkungen || '',
            kostenstelle: this.kostenstelle || '',
            kostentraeger: this.kostentraeger || '',
            kennzeichen: this.kennzeichen || '',
            mehrtaegig: this.mehrtaegig ? true : false,
            bis_datum: this.bis_datum || []
        }
    }
}

export default Reisekosten